./RedButton.vue
<script setup lang="ts">
import type { PropType } from 'vue'

export type TSize = 'small' | 'normal' | 'medium'

const props = defineProps({
  text: {
    type: String,
    default: ''
  },
  size: {
    type: String as PropType<TSize>,
    default: 'normal'
  },
  disabled: {
    type: Boolean,
    default: false
  },
  loading: {
    type: Boolean,
    default: false
  },
  to: {
    type: String,
    default: ''
  },
  mode: {
    type: String as PropType<'clickEmit' | 'navigation' | 'windowOpen'>,
    default: 'clickEmit'
  }
})

const emit = defineEmits(['click'])

function handleClick() {
  if (props.loading || props.disabled) {
    return
  }
  switch (props.mode) {
    case 'navigation':
      if (props.to) {
        navigateTo(props.to)
      }
      break
    case 'windowOpen':
      if (props.to) {
        window.open(props.to)
      }
      break
    default:
      emit('click')
      break
  }
}
</script>
<template>
  <button
    class="rounded-[0.3125rem] p-[0.625rem]"
    :aria-label="props.text"
    type="button"
    :class="{
      'active:ring-none text-primary hover:bg-none hover:ring-[1px] hover:ring-primary active:bg-primary active:text-white':
        !props.disabled,
      'bg-gray-30 text-white': props.disabled,
      'text-t6 font-light': props.size === 'small',
      'text-h5 font-medium': props.size === 'normal',
      'text-h4 font-medium': props.size === 'medium'
    }"
    :disabled="props.disabled"
    @click.stop="handleClick"
  >
    <div
      :class="{
        'h-[1.125rem] w-[1.125rem]': props.size === 'small',
        'h-[1.25rem] w-[1.25rem]': props.size === 'normal',
        'h-[1.5rem] w-[1.5rem]': props.size === 'medium'
      }"
    >
      <slot name="icon" :disabled="props.disabled"></slot>
    </div>
  </button>
</template>
